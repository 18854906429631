import { useIsAuthenticated } from "@azure/msal-react";
import { Container, Nav, Navbar, NavDropdown } from "react-bootstrap";
import logo from './logo.png';
import styles from './Header.module.css';
import { SignOutButton } from "./SignOutButton";
import { SignInButton } from "./SignInButton";
import { WelcomeUser } from "./WelcomeUser";

// header includes the logo and navigation controls of the entire site
export function Header () {
  const isAuthenticated = useIsAuthenticated();

  return (
    <Navbar bg="light" variant="light" expand="lg">
    <Container>
      <Navbar.Brand href="/"><img src={logo} className={styles.logo} alt="logo" /></Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="me-auto">
          <Nav.Link href="/">Home</Nav.Link>
          { isAuthenticated ? <Nav.Link href="/token">Token</Nav.Link> : null}
          <NavDropdown title="Cards" id="basic-nav-dropdown">
            <NavDropdown.Item href="/cards/present">Present</NavDropdown.Item>
            { isAuthenticated ? 
            <>
            <NavDropdown.Divider />
            <NavDropdown.Item href="/cards/download">Download</NavDropdown.Item>
            <NavDropdown.Item href="/cards/list">List</NavDropdown.Item>
            </> : null }
          </NavDropdown>
          </Nav>
          <Nav>
          { isAuthenticated ? 
          <><Navbar.Text><WelcomeUser /></Navbar.Text>&nbsp;&nbsp;&nbsp;<SignOutButton /></> : 
          <SignInButton /> }
        </Nav>
      </Navbar.Collapse>
    </Container>
  </Navbar>
  );
} 